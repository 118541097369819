@media (max-width: 600px) {
  .sidebar {
    display: none;
    margin: none;
  }

  .color {
    margin: 0px;
  }
}

.paletteBody {
  border-radius: 20px;
  background-color: rgb(255, 226, 212);
  /* padding: 0 5%; */
  max-width: 900px;
  width: fit-content;
  min-width: 300px;
  color: rgb(51, 51, 90);
  margin: 25px 10px;
}



.palette-title {
  padding: 0% 5%;
}

.palette-comment {
  font-size: 1em;
  padding: .5em 10%;
  /* color: #33335A; */
}

.divider {
  margin: 15px 30px 20px;
  box-shadow: none;
  border-radius: 3px;
  border-width: 1px;

}


.bottom-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 00px;

  height: 75px;
  background-color: rgb(143, 109, 143);
  margin-top: 2.5%;
  border-radius: 0 0 20px 20px;
}

/* Bottom bar descendents */
.bottom-bar * {
  background-color: inherit;
}

.bar-field {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
  font-weight: bold;
}

.bar-button {
  height: 40px;
  width: 40px;
  margin: 0px 2%;

  padding: 0 0px 3px 0px;
  /* padding: 1px 0px 0px 3px; */

  border-radius: 25px;
  border-width: 3px;
  /* padding-right: 10px; */

  /* display:block; */
  text-align: center;

  font-size: 20px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-out;
}

.bar-button:hover {
  transform: scale(1.03);
}

.action-buttons {
  /* overflow-x: hidden; */
  /* width: fit-content; */
  width: 160px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}

.big {
  width: 50px;
  height: 50px;
  font-size: 25px;
  padding-left: 1px;
  padding-bottom: 1px;
}

.icon {
  background-color: transparent;
  margin: 5px 2px 0px 0px;
  text-align: center;
  height: fit-content;
  width: fit-content;
}

.palette {
  display: flex;
  flex-wrap: nowrap;
  /* margin: 20px; */
  justify-content: center;
  padding: 0 10%;
}

.color {
  width: 20%;

  max-height: 250px;
  /* height: 300px; */
  min-height: 200px;
  margin: 1%;
  border-radius: 10px;
  /* position: relative; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  border-width: 0px;
  border-style: solid;
  padding: 2.5px;
}

.shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.color-label {
  font-size: 1em;
  opacity: 0;
  transition: opacity 0.3s;
  /* transition: transform 0.2s l; */
  text-align: center;
}

.color:hover .color-label {
  opacity: 1;
  /* transform: scale(1.02); */
}

.hex-text {
  font-weight: bold;
  /* transition: background-color 0.3s ease; */
  width: 100%;
  font-size: 1em;
}

.hex-text:hover {
  background-color: black;
}

.disabled {
  cursor: not-allowed !important;
  pointer-events: none;
  color: GrayText;
}