:root {
    --color-0: #4a5568;
    --color-1: #2d3748;
    --color-2: #e2e8f0;
    --color-4: #edf2f7;
    --color-3: #FF9F1C;
    --color-3-dark: #D16D17;

    --dark-color: #2d3748;

    --toastify-color-dark: #2d3748;
}

/* :root {
    --color-0: #A0C9A5;
    --color-1:  #9CBFA1;
    --color-2:  #D0D6C1;
    --color-3:  #C4F1CD;
    --color-4:  #F7F3E1;

    --dark-color: #2C2A2B ;
} */

/* html, body {margin: 0; padding: 0; overflow-x:hidden;} */
.App {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;


    background-color: var(--color-4);
    color: var(--dark-color);
}

@media (max-width: 600px) {
    .sidebar {
        display: none;
    }
}

.sidebar {
    width: 230px;
    min-width: 50px;
    height: 100%;
    /* overflow: auto; */
    padding: 0 15px;
    Overflow-y: scroll;
}

.Body {
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    height: 100%;
    padding: 0px 2.5%;
    flex-flow: column nowrap;
    /* justify-content: center; */
    align-items: center;
    Overflow-y: scroll;
    /* width: 100vw;
    height: 100vh; */
}





.header {
    width: 100%;
    padding: 10px 0 0px;
    /* min-height: 50px; */
    font-size: 4em;
    font-weight: bold;
    text-align: center;
}

.subheader {
    margin-top: 5px;
    margin-bottom: 30px;
}

.toolbar {
    height: 50px;
    /* text-align: center; */
    padding: 1%;
    width: 80%;
    /* width: 100%; */

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-end;
}

.button-bar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.text-input {
    min-width: 20%;
    width: 500px;
    height: 30px;
    font-size: 1.25em;
    /* border-radius: 5px; */
    border-width: 0px;
    border-bottom-width: 2px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    background-color: inherit;
    /* margin-bottom: 15px; */
    border-color: var(--dark-color);
    color: var(--dark-color)
}

.text-input:focus {
    border-bottom-width: 2px;
    outline: none;
}


.loadingIndicator {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    /* height: 100%; */
    height: 200px;
    padding-top: 20%;
    margin-right: 25px;
    text-align: center;
}

.loading-text {
    padding: 0 0 30px 16px;
    margin-top: -90px;
    opacity: .7;
}



.saved-palette {
    padding-top: 12px;

    transition: transform 0.2s ease;
    /* Add a transition effect */
}

.saved-palette:hover {
    transform: scale(1.05);
    /* Expand the div on hover */
}

.saved-palette:active {
    transform: scale(0.97);
    /* Shrink the div on click */
}

.saved-colors {
    display: flex;
    flex-wrap: wrap;
}

.saved-color {
    width: 20%;
    height: 60px;
}

.disabled {
    cursor: not-allowed !important;
    pointer-events: none;
    color: GrayText;
}







.button {
    background-color: var(--color-3);
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0px 5px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
    transition: 0.3s;

    height: 35px;
    min-width: 100px;
}

.button:hover {
    background-color: var(--color-3-dark);
    color: white;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}

.button:active {
    background-color: var(--color-3-dark);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
    transform: translateY(2px);
    transition: 0.3s;
}